import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const BlogLayoutWrapper = styled.div`
  position: relative;
  max-width: 100%;
  padding: 1rem 3rem;
`

const FeaturedImage = styled.img`
  max-width: 100%;
  margin: 16px 0;
`

const BlogTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  color: #171717;
  font-size: 45px;
  font-weight: 300;
`

const Blog = ({ pageContext }) => {
  return (
    <Layout>
      <BlogLayoutWrapper>
        <BlogTitle>{pageContext.title}</BlogTitle>
        <FeaturedImage src={pageContext.featured_media.source_url} />
        <div dangerouslySetInnerHTML={{ __html: pageContext.content }} />
      </BlogLayoutWrapper>
    </Layout>
  )
}

export default Blog
